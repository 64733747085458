const onFocus = (event: Event) => {
    const target = event.target as HTMLInputElement;
    target.classList.add('is-fill');
};

const onBlur = (event: Event | HTMLInputElement) => {
    const target = event instanceof HTMLInputElement ? event : (event.target as HTMLInputElement);
    target.value === '' ? target.classList.remove('is-fill') : target.classList.add('is-fill');
};

export default function inputFocusInit() {
    const inputs = Array.from(document.querySelectorAll<HTMLInputElement>('.js-input'));

    inputs.forEach((input) => {
        input.addEventListener('focus', onFocus);
        input.addEventListener('blur', onBlur);
        onBlur(input);
    });
}
