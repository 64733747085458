import delegate from 'delegate';
import axios from 'axios';

export default function navChangeHandlerInit() {
    const contentList = document.querySelector('.js-content-list');
    const filtersList = document.querySelector('.js-filters-layout');
    const form = document.querySelector<HTMLFormElement>('.js-filters-form');
    const button = document.querySelector<HTMLElement>('.js-show-more');
    const defOffset = button?.dataset.offset


    delegate('.js-nav-filter-checkbox', 'change', (event) => {
        if (form) {
            // formData.append('action', 'filter_products')
            const action = form.getAttribute('action');
            if (action) {

                const industries = document.querySelectorAll('input[name = "'+event.target.name+'"]:checked'); 

                const arrId = [];
                industries.forEach((item) => {
                  //arrId[] = item.getAttribute('id');
                  arrId.push(item.value);
                })

                const cookieValue = JSON.stringify(arrId);

                document.cookie = event.target.name+"="+cookieValue+"; path=/;";
                const formData = new FormData(form);

                axios.post(action,  form, {
                    headers: {
                        'Content-Type': 'text/json'
                    }
                })
                    .then(({data}) => {
                        if (contentList && data && data.html) {
                            button.dataset.offset = String(Number(defOffset))
                            contentList.innerHTML = data.html;
                        }
                        if (button) {
                            if (data.data && data.data.is_end) {
                                button.classList.add('visually-hidden');
                            } else {
                                button.classList.remove('visually-hidden');
                            }
                        }
                    })
            }
        }
    });

    button?.addEventListener('click', (event) => {
        const action = button.dataset.action;
        if (form && action) {
            const formData = new FormData(form);

            formData.append('offset', button.dataset.offset)
            formData.append('defOffset', defOffset)

            axios.post(action,  formData, {
                    headers: {
                        'Content-Type': 'text/json'
                    }
                }).then(({data})=> {
                    button.dataset.offset = String(Number(button.dataset.offset) + Number(defOffset))
                    if(data.html) {
                        contentList.innerHTML = data.html;
                    }
                if (data.data && data.data.is_end) {
                    button.classList.add('visually-hidden');
                } else {
                    button.classList.remove('visually-hidden');
                }
                if (contentList && data.data && data.data.layout) {
                    contentList.insertAdjacentHTML('beforeend', data.data.layout );
                }
            })
        }
    })

};