import Swiper,  { Navigation } from 'swiper';
import 'swiper/css';

export default function teamSliderInit() {
    const sliderContainers = Array.from(document.querySelectorAll<HTMLElement>('.js-team-slider-container'));

    sliderContainers.forEach((container) => {
        
        const sliderElement = container.querySelector<HTMLElement>('.js-slider-element')
        const navigationElement = container.querySelector<HTMLElement>('.js-slider-navigation');

        let navigationNextEl;
        let navigationPrevEl;

        if (navigationElement) {
            navigationNextEl = navigationElement.querySelector<HTMLElement>('.js-slider-arrow-next');
            navigationPrevEl = navigationElement.querySelector<HTMLElement>('.js-slider-arrow-prev');
        }

        if (sliderElement) {
            const slides = sliderElement.querySelectorAll('.swiper-slide');

            /* if (window.matchMedia('(min-width: 1160px)').matches && slides.length < 4) {
                return
            }

            if (window.matchMedia('(max-width: 1159px)').matches && slides.length < 2) {
                return
            } */

            const swiper = new Swiper(sliderElement, {
                modules: [Navigation],
                loop: true,
                slidesPerView: 1,
                autoHeight: true,
                on: {
                    init: () => {
                        container.classList.add('slider-inited')
                    }
                },
                /* breakpoints: {
                    1160: {
                        slidesPerView: 3,
                    },
                }, */

                // Navigation arrows
                navigation: navigationNextEl && navigationPrevEl ? {
                    nextEl: navigationNextEl,
                    prevEl: navigationPrevEl,
                } : false,
            });
        }
    })
}