import SlimSelect from "slim-select";

export default function initSelects() {

    const selects = Array.from(document.querySelectorAll('.js-select'));

    selects.forEach((select) => {
        new SlimSelect({
            select: select,
            showSearch: false,
            onChange: () => {
                select.classList.add('is-touched')
            }
        });
    })
}