/* eslint-disable max-len */
function getParameterByName(name: string) {
    const nameInner = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + nameInner + "=([^&#]*)");
    const results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export default function initFormInputs() {
    const inputsFormPageUrl = Array.from(document.querySelectorAll<HTMLInputElement>('.js-form-page-url'));
    const inputsFormPageTitle = Array.from(document.querySelectorAll<HTMLInputElement>('.js-form-page-title'));
    const inputsFormCrmTags = Array.from(document.querySelectorAll<HTMLInputElement>('.js-form-crm-tags'));
    const inputsFormCrmGroup = Array.from(document.querySelectorAll<HTMLInputElement>('.js-form-crm-group'));
    const inputsFormMarketingNumber = Array.from(document.querySelectorAll<HTMLInputElement>('.js-form-marketing-number'));
    const inputsFormIdGetResponse = Array.from(document.querySelectorAll<HTMLInputElement>('.js-form-id-get-response'));
    const inputsFormUtmSource = Array.from(document.querySelectorAll<HTMLInputElement>('.js-form-utm-source'));
    const inputsFormUtmMedium = Array.from(document.querySelectorAll<HTMLInputElement>('.js-form-utm-medium'));
    const inputsFormUtmCampaing = Array.from(document.querySelectorAll<HTMLInputElement>('.js-form-utm-campaing'));
    const inputsFormUtmTerm = Array.from(document.querySelectorAll<HTMLInputElement>('.js-form-utm-term'));
    const inputsFormUtmContent = Array.from(document.querySelectorAll<HTMLInputElement>('.js-form-utm-content'));
    const documentTitle = document.title;
    const idGetResponseElement = document.querySelector<HTMLElement>('.js-id-getresponse');
    const crmTagsElement = document.querySelector<HTMLElement>('.js-crm-tags');
    const crmGroupElement = document.querySelector<HTMLElement>('.js-crm-group');
    const marketingNumber = document.querySelector<HTMLElement>('.js-marketing-number');
    const idGetResponseValue = idGetResponseElement && idGetResponseElement.dataset && idGetResponseElement.dataset.value ? idGetResponseElement.dataset.value : '';
    const crmTagsElementValue = crmTagsElement && crmTagsElement.dataset && crmTagsElement.dataset.value ? crmTagsElement.dataset.value : '';
    const crmGroupElementValue = crmGroupElement && crmGroupElement.dataset && crmGroupElement.dataset.value ? crmGroupElement.dataset.value : '';
    const marketingNumberElementValue = marketingNumber && marketingNumber.dataset && marketingNumber.dataset.value ? marketingNumber.dataset.value : '';

    const source = getParameterByName('utm_source');
    const medium = getParameterByName('utm_medium');
    const campaign = getParameterByName('utm_campaign');
    const term = getParameterByName('utm_term');
    const content = getParameterByName('utm_content');

    inputsFormPageUrl.forEach((input) => input.value = window.location.href);
    inputsFormPageTitle.forEach((input) => input.value = documentTitle);
    inputsFormCrmTags.forEach((input) => input.value = crmTagsElementValue);
    inputsFormCrmGroup.forEach((input) => input.value = crmGroupElementValue);
    inputsFormMarketingNumber.forEach((input) => input.value = marketingNumberElementValue);
    inputsFormIdGetResponse.forEach((input) => input.value = idGetResponseValue);
    inputsFormPageTitle.forEach((input) => input.value = documentTitle);
    inputsFormUtmSource.forEach((input) => source ? input.value = source : input.value = '');
    inputsFormUtmMedium.forEach((input) => medium ? input.value = medium : input.value = '');
    inputsFormUtmCampaing.forEach((input) => campaign ? input.value = campaign : input.value = '');
    inputsFormUtmTerm.forEach((input) => term ? input.value = term : input.value = '');
    inputsFormUtmContent.forEach((input) => content ? input.value = content : input.value = '');
}
