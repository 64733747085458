import Swiper,  { Pagination, Navigation } from 'swiper';
import 'swiper/css';


export default function sliderGalleryInit() {
    const sliderContainers = Array.from(document.querySelectorAll<HTMLElement>('.js-slider-gallery-container'));

    sliderContainers.forEach((container) => {
        const sliderElement = container.querySelector<HTMLElement>('.js-slider-element')
        const paginationElement = container.querySelector<HTMLElement>('.js-slider-pagination');
        const navigationElement = container.querySelector<HTMLElement>('.js-slider-navigation');

        let navigationNextEl;
        let navigationPrevEl;

        if (navigationElement) {
            navigationNextEl = navigationElement.querySelector<HTMLElement>('.js-slider-arrow-next');
            navigationPrevEl = navigationElement.querySelector<HTMLElement>('.js-slider-arrow-prev');
        }

        if (sliderElement) {
            const slides = sliderElement.querySelectorAll('.swiper-slide');

            if (slides.length < 2) {
                navigationElement?.classList.add('visually-hidden')
                return
            }

            const swiper = new Swiper(sliderElement, {
                modules: [Pagination, Navigation],
                loop: true,
                slidesPerView: 1,
                spaceBetween: 10,
                grabCursor: true,
                breakpoints: {
                    768: {
                        slidesPerView: 1.2,
                        spaceBetween: 40
                    },
                    1160: {
                        slidesPerView: 2.1,
                    }
                },
                pagination: paginationElement ? {
                    el: paginationElement,
                    enabled: true,
                    clickable: true,
                    type: 'bullets',
                    renderBullet: (index, className) => {
                        return `<span class="bullet-pagination ${className}"><span></span></span>`
                    }
                } : false,

                navigation: navigationNextEl && navigationPrevEl ? {
                    nextEl: navigationNextEl,
                    prevEl: navigationPrevEl,
                } : false,
            });
        }
    })
}